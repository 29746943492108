* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

input {
  border: 1px solid rgb(189, 189, 189);
  outline: none;
}

input:focus {
  border: 2px solid #29b1ac;
}

.green-svg-color {
  filter: invert(54%) sepia(71%) saturate(419%) hue-rotate(129deg) brightness(95%) contrast(92%);
}

.white-svg-color {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(183deg) brightness(107%) contrast(102%);
}

.c-sidebar .c-sidebar-brand {
  background-color: #58585A;
}

.c-side-bar-nav {
  background-color: #29B1AC;
}

.c-sidebar-nav-item {
  background-color:#29B1AC;
}


.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover
{
  background-color: #C78822;
}

 
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle  {
  background-color: #F1B800;
}



.c-sidebar .c-sidebar-minimizer {
  background-color: #58585A;
}

@media (max-width: 991px) {
  .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    background-color: #C78822;
  }
  
}